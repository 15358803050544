<template>
  <div class="create-content-main">
    <div class="create-form p-0">
      <CCard style="border: unset; border-radius: unset; border-color: unset">
        <CCardHeader
          class="d-flex justify-content-between"
          style="border-bottom: unset"
        >
          <span class="font-title px-4 py-2">お問い合わせ管理</span>
        </CCardHeader>
        <CCardBody class="p-0">
          <div class="">
            <CRow class="p-0">
              <CCol sm="12">
                <label class="custom-text cp-label">メールアドレス</label>
                <div class="cp-4 w-100">
                  <div class="d-flex">
                    <CInput
                      class="w-100 m-0"
                      v-model="inquiry.email"
                      disabled
                    />
                    <CButton
                      v-clipboard:copy="inquiry.email"
                      :color="'secondary'"
                      :size="'sm'"
                      class="ml-3 m-0"
                      style="margin-bottom: 1rem; min-width: 170px"
                    >
                      メールアドレスをコピー
                    </CButton>
                  </div>
                </div>
              </CCol>
              <CCol sm="12">
                <label class="custom-text cp-label">氏名</label>
                <div class="cp-4 w-100">
                  <div class="d-flex">
                    <CInput class="w-100 m-0" v-model="inquiry.name" disabled />
                    <CButton
                      v-clipboard:copy="inquiry.name"
                      :color="'secondary'"
                      :size="'sm'"
                      class="ml-3 mb-0"
                      style="margin-bottom: 1rem; min-width: 170px"
                    >
                      氏名をコピー
                    </CButton>
                  </div>
                </div>
              </CCol>
              <CCol sm="12">
                <label class="custom-text cp-label">お問い合わせ内容</label>
                <div class="cp-4 w-100">
                  <CTextarea v-model="inquiry.message" disabled rows="10" />
                </div>
              </CCol>
              <CCol sm="12">
                <label class="custom-text cp-label">ステータス</label>
                <div class="cp-4 w-100">
                  <div class="d-flex">
                    <CSelect
                      v-model="inquiry.status"
                      :options="optionsStatus"
                    />
                    <CButton
                      :color="'secondary'"
                      :size="'sm'"
                      class="ml-3"
                      style="margin-bottom: 1rem"
                      @click="updateStatusInquiries()"
                    >
                      ステータスを変更
                    </CButton>
                  </div>
                </div>
              </CCol>
            </CRow>
            <div v-for="(value, index) in inquiry.column" :key="index">
              <CRow class="mb-3" v-if="inquiry.column.length > 0">
                <CCol sm="12" v-if="value.type === 1">
                  <label class="w-100 custom-text cp-label">{{
                    value.form.label
                  }}</label>
                  <div class="d-flex cp-4">
                    <b-form-input
                      class="w-100"
                      v-model="value.form.value"
                    ></b-form-input>
                  </div>
                </CCol>
                <CCol sm="12" v-if="value.type === 2">
                  <label class="w-100 custom-text cp-label">{{
                    value.form.label
                  }}</label>
                  <div class="d-flex cp-4">
                    <CTextarea
                      class="w-100"
                      v-model="value.value"
                      rows="10"
                    ></CTextarea>
                  </div>
                </CCol>
                <CCol sm="12" v-if="value.type === 3">
                  <label class="w-100 custom-text cp-label">{{
                    value.form.label
                  }}</label>
                  <div class="d-flex cp-4">
                    <b-form-select
                      v-model="value.form.value"
                      :options="value.form.options"
                    ></b-form-select>
                  </div>
                </CCol>
                <CCol sm="12" v-if="value.type === 4">
                  <label class="w-100 custom-text cp-label">{{
                    value.form.label
                  }}</label>
                  <div class="d-flex cp-4">
                    <b-form-radio-group
                      v-model="value.form.value"
                      :options="value.form.options"
                      :name="value.form.name"
                    ></b-form-radio-group>
                  </div>
                </CCol>
                <CCol sm="12" v-if="value.type === 5">
                  <label class="w-100 custom-text cp-label">{{
                    value.form.label
                  }}</label>
                  <div class="d-flex cp-4">
                    <b-form-checkbox-group
                      v-model="value.form.value"
                      :options="value.form.options"
                      :name="value.form.name"
                    ></b-form-checkbox-group>
                  </div>
                </CCol>
              </CRow>
            </div>
            <CRow>
              <CCol sm="12" class="pb-4">
                <div class="d-flex justify-content-center">
                  <CButton
                    v-if="listInquiry.current_page !== 1 || indexSelect !== 0"
                    v-on:click.prevent="previous()"
                    :color="'outline-secondary'"
                    :size="'lg'"
                    class="mt-3 mr-3 text-white"
                    style="height: 42px; background-color: #00b797"
                  >
                    ＜前へ
                  </CButton>
                  <CButton
                    v-if="
                      listInquiry.data &&
                      (listInquiry.current_page !== listInquiry.last_page ||
                        indexSelect !== listInquiry.data.length - 1)
                    "
                    v-on:click.prevent="next()"
                    :color="'outline-secondary'"
                    :size="'lg'"
                    class="mt-3 mr-3 text-white"
                    style="height: 42px; background-color: #00b797"
                  >
                    次へ＞
                  </CButton>
                  <CButton
                    v-on:click.prevent="goToListInquiry()"
                    :color="'secondary'"
                    :size="'lg'"
                    class="mt-3 back-btn"
                    style="height: 42px"
                  >
                    戻る
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";

export default {
  name: "CreateUser",

  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      indexSelect: this.$route.params.index,
      no: this.$route.query.NO ? this.$route.query.NO : "",
      inquiry: {
        id: "",
        email: "",
        name: "",
        message: "",
        column: "",
        status: "",
      },
      page: 1,
      callFirstTime: false,
      optionsStatus: Constants.STATUS_INQUIRY,
    };
  },
  created() {
    this.getListInquiries(this.page);
  },
  computed: {
    ...mapGetters(["success", "message", "error", "listInquiry"]),
  },
  watch: {
    listInquiry() {
      let result;
      if (this.listInquiry.data.length && this.callFirstTime) {
        this.indexSelect =
          this.direct === "next" ? 0 : this.listInquiry.data.length - 1;
      } else {
        this.callFirstTime = true;
      }
      result = this.listInquiry.data.filter(
        (item, index) => index == this.indexSelect
      );
      if (result.length > 0) {
        this.inquiry = result[0];
      }
    },
    success() {
      if (this.success) {
        this.$toasted.clear();
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.clear();
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  methods: {
    ...mapActions({ getListInquiry: "getListInquiry" }),
    ...mapActions({ updateStatusInquiry: "updateStatusInquiry" }),
    previous() {
      this.direct = "previous";
      this.indexSelect -= 1;
      this.inquiry = this.listInquiry.data.filter(
        (item, index) => index === this.indexSelect
      )[0];
      if (!this.inquiry) {
        this.page -= 1;
        this.getListInquiries(this.page);
      }
    },
    next() {
      this.direct = "next";
      this.indexSelect += 1;
      this.inquiry = this.listInquiry.data.filter(
        (item, index) => index === this.indexSelect
      )[0];
      if (!this.inquiry) {
        this.page += 1;
        this.getListInquiries(this.page);
      }
    },
    getListInquiries(page) {
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
        },
      };
      this.getListInquiry(formData);
    },
    updateStatusInquiries() {
      const formData = {
        id: this.inquiry.id,
        shop_id: this.shop_id,
        status: Constants.STATUS_INQUIRY.indexOf(this.inquiry.status),
      };
      this.updateStatusInquiry(formData);
    },
    goToListInquiry() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "list inquiry"
          : "list inquiry domain",
      });
    },
  },
};
</script>
<style scoped>
textarea {
  height: 150px !important;
}
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin-top: 0px !important;
}
.row {
  margin-right: 0;
  margin-left: 0;
}
.col-sm-12 {
  padding: 0;
}
.create-form input,
.create-form >>> textarea {
  pointer-events: none;
  background: #f3f3f3;
}
</style>
